import store from '@/store/index.js'

const XLSX = require('xlsx')

const Utils = XLSX.utils // XLSX.utilsのalias
const { format, callbackify } = require('util')

// TODO: 意味のない処理
const revert = function (uniqueFileId, load, error) {
  store.dispatch('studentRegister', {
    data: null,
  })
}

const process = function (fieldName, file, metadata, load, error, progress, abort, transfer, options) {
  const reader = new FileReader()

  // reader.readAsArrayBufferで呼ばれる関数
  reader.onload = async function (e) {
    try {
      const data = e.target.result
      const arr = fixdata(data)
      const wb = XLSX.read(btoa(arr), {
        type: 'base64',
        cellDates: true,
      })

      const sheet_name_list = wb.SheetNames

      // const HEADER_SHEET_NUM = 0 // ヘッダーレコード作成用シート番号
      const DATA_SHEET_NUM = 1 // データレコード作成用シート番号

      // await store.dispatch(`setting_bank/readSettingBank`)

      const setting_bank = store.getters['setting_bank/getSettingBank']

      const header_by_bank_code = {}

      for (const i in setting_bank) {
        const setting_bank_code = String(Number(setting_bank[i].bank_code))
        if (!(setting_bank_code in header_by_bank_code)) {
          const data = [
            setting_bank[i].consigner_code,
            setting_bank[i].consigner_name,
            String(setting_bank[i].bank_name_kana),
            setting_bank[i].bank_code,
            setting_bank[i].branch_name_kana,
            setting_bank[i].branch_code,
            setting_bank[i].acc_type_name,
            setting_bank[i].acc_num,
          ]
          header_by_bank_code[setting_bank_code] = data
        }
      }

      console.log('header_by_bank_code:', header_by_bank_code)

      const data_sheet = wb.Sheets[sheet_name_list[DATA_SHEET_NUM]]

      const data_from_excel = { sousin_date: '', furikae_date: '', furikae_data: [] } // エクセルから抽出したデータ
      // 日付をセット
      data_from_excel.sousin_date = store.state.zengin_data.sousin_date
      data_from_excel.furikae_date = store.state.zengin_data.furikae_date

      const data_by_bank_code = {}

      const total_num_by_bank_code = {}
      const total_money_amount_by_bank_code = {}
      const branch_name_to_code = {}
      const branch_code_to_name = {}

      let data_row_num = 2 // 頭の行は見出しなので，行番号は1からスタート

      // TODO: whileの条件を確認
      while (data_sheet[`A${data_row_num}`].v != 0) {
        console.log('読み込み')
        const excel_is_acc = validate_is_acc(data_sheet[`C${data_row_num}`], data_row_num)
        const excel_is_claim = validate_is_claim(data_sheet[`E${data_row_num}`], data_row_num)
        const excel_money_amount = validate_money_amount(data_sheet[`D${data_row_num}`], data_row_num)
        const excel_bank_code = validate_bank_code(data_sheet[`H${data_row_num}`], data_row_num)

        // 読み込んだ行が「契約」かつ請求「有」
        if (excel_is_acc == '契約' && excel_is_claim == '有' && Number(excel_money_amount) >= 1) {
          // 銀行コードがヘッダーの銀行コードに含まれる
          if (excel_bank_code in header_by_bank_code) {
            // 各値のバリデーションを行う
            const excel_bank_name = validate_bank_name(data_sheet[`G${data_row_num}`], data_row_num)
            const excel_branch_name = validate_branch_name(data_sheet[`I${data_row_num}`], data_row_num)
            const excel_branch_code = validate_branch_code(data_sheet[`J${data_row_num}`], data_row_num)
            const excel_acc_holder = validate_acc_holder(data_sheet[`K${data_row_num}`], data_row_num)
            const excel_acc_type = validate_acc_type(data_sheet[`L${data_row_num}`], data_row_num)
            const excel_acc_num = validate_acc_num(data_sheet[`M${data_row_num}`], data_row_num)
            const excel_customer_code = validate_customer_code(data_sheet[`A${data_row_num}`], data_row_num)

            const bank_branch_code =
              String(excel_bank_code).padStart(4, '0') + '-' + String(excel_branch_code).padStart(3, '0')

            console.log('bank_branch_code = ', bank_branch_code)

            if (!(header_by_bank_code[excel_bank_code][2] == excel_bank_name)) {
              throw new Error(`invalid confignor or bank_name=${data_row_num}`)
            }
            if (bank_branch_code in branch_code_to_name) {
              if (branch_code_to_name[bank_branch_code] != excel_branch_name) {
                throw new Error(`invalid branch_code=${data_row_num}`)
              }
            } else {
              branch_code_to_name[bank_branch_code] = excel_branch_name
            }
            console.log('branch_code_to_name', branch_code_to_name)
            if (excel_branch_name + excel_bank_code in branch_name_to_code) {
              if (branch_name_to_code[excel_branch_name + excel_bank_code] != excel_branch_code) {
                throw new Error(`invalid branch_code=${data_row_num}`)
              }
            } else {
              branch_name_to_code[excel_branch_name + excel_bank_code] = excel_branch_code
            }
            const data = [
              excel_bank_name,
              excel_bank_code,
              excel_branch_name,
              excel_branch_code,
              excel_acc_type,
              excel_acc_num,
              excel_acc_holder,
              excel_money_amount,
              excel_customer_code,
            ]

            // 銀行コードをkeyとしたデータが存在する
            if (excel_bank_code in data_by_bank_code) {
              data_by_bank_code[excel_bank_code].push(data)
              total_money_amount_by_bank_code[excel_bank_code] += excel_money_amount
              total_num_by_bank_code[excel_bank_code] += 1
            } else {
              data_by_bank_code[excel_bank_code] = [data]
              total_num_by_bank_code[excel_bank_code] = 1
              total_money_amount_by_bank_code[excel_bank_code] = excel_money_amount
            }
          } else {
            throw new Error(`invalid confignor or bank_code=${data_row_num}`)
          }
        }
        data_row_num++
      }

      // 銀行コードをキー値にstoreに格納するデータ構造に変換
      for (const key in header_by_bank_code) {
        const data_by_header = {
          header_record: [],
          data_record: [],
          total_num: 0,
          total_money_amount: 0,
          // consigner_code: '',
          // consigner_name: '',
        }
        if (data_by_bank_code[key]) {
          data_by_header.header_record = header_by_bank_code[key]
          data_by_header.data_record = data_by_bank_code[key]
          data_by_header.total_num = Number(total_num_by_bank_code[key])
          data_by_header.total_money_amount = total_money_amount_by_bank_code[key]
          // data_by_header.consigner_code =
          // data_by_header.consigner_name =
          data_from_excel.furikae_data.push(data_by_header)
        }
      }

      store.commit('zengin_data/setDataFromExcel', data_from_excel)
    } catch (e) {
      abort() // filepondの処理中断(Process完了イベントを発生させない)
      error(e.message) // filepondのエラーをraise
    }
  }

  // FileReaderのprogressイベントリスナー
  reader.addEventListener('progress', e => {
    // console.log(e.type);
    progress(e.lengthComputable, e.loaded, e.total)
  })

  // FileReaderのload(ファイルの読み込み完了)イベントリスナー(reader.onloadの完了で発火)
  reader.addEventListener('load', e => {
    // console.log(e.type);
    load() // filepondの成功をraise
  })

  reader.readAsArrayBuffer(file) // reader.onloadを実行
}

// // 銀行（支店）名と銀行（支店）コードの組み合わせに外れ値がないかをチェックする
// function check_outlier(bank_branch_list) {
//   const bank_code_to_name = {}
//   const bank_name_to_code = {}

//   // 以下の2つのキー値は銀行コードとセットとする
//   const branch_code_to_name = {}
//   const branch_name_to_code = {}

//   for (let row = 0; row < bank_branch_list.length; row++) {
//     const bank_code = String(bank_branch_list[row][0])
//     const bank_name_ruby = String(bank_branch_list[row][1])
//     const branch_code = String(bank_branch_list[row][2])
//     const branch_name_ruby = String(bank_branch_list[row][3])

//     // キー値として存在する
//     if (
//       bank_code in bank_code_to_name ||
//       bank_name_ruby in bank_name_to_code ||
//       bank_code + branch_code in branch_code_to_name ||
//       bank_code + branch_name_ruby in branch_name_to_code
//     ) {
//       // バリューが一致しない
//       if (bank_code_to_name[bank_code] != bank_name_ruby) {
//         throw new Error(`invalid bank_code=${row + 2}`)
//       }
//       if (bank_name_to_code[bank_name_ruby] != bank_code) {
//         throw new Error(`invalid bank_code=${row + 2}`)
//       }
//       if (branch_code_to_name[bank_code + branch_code] != branch_name_ruby) {
//         throw new Error(`invalid branch_code=${row + 2}`)
//       }
//       if (branch_name_to_code[bank_code + branch_name_ruby] != branch_code) {
//         throw new Error(`invalid branch_code=${row + 2}`)
//       }
//     }

//     // キー値として存在しない
//     else {
//       bank_code_to_name[bank_code] = bank_name_ruby
//       bank_name_to_code[bank_name_ruby] = bank_code
//       branch_code_to_name[bank_code + branch_code] = branch_name_ruby
//       branch_name_to_code[bank_code + branch_name_ruby] = branch_code
//     }
//   }
// }

// 有効/無効が空でないか
function validate_is_acc(is_able, row_num) {
  if (is_able.v == 0) {
    throw new Error(`invalid_is_acc=${row_num}`)
  }

  return is_able.v
}

function validate_is_claim(is_able, row_num) {
  if (is_able.v == 0) {
    throw new Error(`invalid_is_claim=${row_num}`)
  }

  return is_able.v
}

// 銀行名が空でない
function validate_bank_name(bank_name, row_num) {
  if (bank_name.v == 0) {
    throw new Error(`invalid_bank_name=${row_num}`)
  }

  return bank_name.v
}

// 支店名が空でない
function validate_branch_name(branch_name, row_num) {
  if (branch_name.v == 0) {
    throw new Error(`invalid_branch_name=${row_num}`)
  }

  return branch_name.v
}

// 預金者名が空でない
function validate_acc_holder(acc_holder, row_num) {
  if (acc_holder.v == 0) {
    throw new Error(`invalid_acc_holder=${row_num}`)
  }

  return acc_holder.v
}

// 銀行コードが4桁以下であるか
function validate_bank_code(bank_code, row_num) {
  // 空の確認
  if (bank_code.v == 0) {
    throw new Error(`invalid_bank_code=${row_num}`)
  }
  const str_bank_code = String(bank_code.v)

  // 桁数の確認
  if (str_bank_code.length > 4) {
    throw new Error(`invalid_bank_code=${row_num}`)
  }

  return Number(str_bank_code)
}

// 支店コードが3桁以下であるか
function validate_branch_code(branch_code, row_num) {
  if (branch_code.v == 0) {
    throw new Error(`invalid_branch_code=${row_num}`)
  }
  const str_branch_code = String(branch_code.v)
  if (str_branch_code.length > 3) {
    throw new Error(`invalid_branch_code=${row_num}`)
  }

  return Number(str_branch_code)
}

// 預金科目が「普通」もしくは「当座」であるか
function validate_acc_type(acc_type, row_num) {
  if (acc_type.v == 0) {
    throw new Error(`invalid_acc_type=${row_num}`)
  }
  const str_acc_type = acc_type.v
  if (str_acc_type != '普通' && str_acc_type != '当座') {
    throw new Error(`invalid_acc_type=${row_num}`)
  }

  return str_acc_type
}

// 口座番号が7桁以下であるか
function validate_acc_num(acc_num, row_num) {
  if (acc_num.v == 0) {
    throw new Error(`invalid_acc_num=${row_num}`)
  }
  const str_acc_num = String(acc_num.v)
  if (str_acc_num.length > 7) {
    throw new Error(`invalid_acc_num=${row_num}`)
  }

  return Number(str_acc_num)
}

// 顧客番号が20桁以下であるか
function validate_customer_code(customer_code, row_num) {
  if (customer_code.v == 0) {
    throw new Error(`invalid_customer_code=${row_num}`)
  }
  const str_customer_code = String(customer_code.v)
  if (str_customer_code.length > 20) {
    throw new Error(`invalid_customer_code=${row_num}`)
  }

  return str_customer_code
}

// 引落請求金額が10桁以下であるか
function validate_money_amount(money_amount, row_num) {
  if (money_amount == null) {
    throw new Error(`invalid_money_amount=${row_num}`)
  }
  const str_money_amount = String(money_amount.v)
  if (str_money_amount.length > 10) {
    throw new Error(`invalid_money_amount=${row_num}`)
  }

  return money_amount.v
}

function fixdata(data) {
  let o = ''
  let l = 0
  const w = 10240
  for (; l < data.byteLength / w; ++l) {
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
  }
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))

  return o
}

function initOptions() {
  return {
    labelIdle: "<span class='filepond--label-action'>ファイルを選択</span> or ドラック＆ドロップ",
    labelFileProcessing: '登録中',
    labelFileProcessingComplete: '登録完了',
    labelFileProcessingError: '登録失敗',
    labelFileTypeNotAllowed: 'ファイル形式エラー',
    allowMultiple: true,
    maxFiles: '1',
    instantUpload: false,
    allowProcess: false,
    allowRevert: false,
    dropOnPage: true,
    maxFileSize: '50MB',
    acceptedFileTypes: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    server: {
      process,
      revert,
    },
  }
}

export default initOptions
