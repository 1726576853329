<template>
  <div class="step-body mt-10 mb-10">
    <div class="step-title-area">
      <div id="step3-title" class="step-title">
        Step.3 Excelファイルの登録
      </div>
      <hr class="step-title-hr" />
    </div>
    <div class="step-detail-area">
      <!-- <p>口座情報と集金計画が記入されたExcelファイルをブラウザに読み込みます。</p> -->
      <div class="step-detail-alert-area">
        <ol>
          <li>テンプレートの<b>Excelファイルをダウンロード</b>して、口座情報や金額を記入してください。<br><b>※既に記入済みのExcelファイルをお持ちの場合、この手順は不要です。</b></li>
          <v-btn
            elevation="1"
            outlined
            color="primary"
            class="btn btn-success btn-lg btn-fill download-btn"
            @click="clickDownload"
          >
            <i class="ti-download"></i>
            テンプレートファイルをダウンロード
            <v-icon>{{ icons.mdiDownload }}</v-icon>
          </v-btn>
          <li>
            Excel登録フォームにExcelファイルをドラッグ＆ドロップ、もしくは、Excel登録フォームをクリックして<b>Excelファイルを選択</b>してください。
          </li>
          <li><b>登録開始ボタンをクリック</b>すると、Excelファイルの登録が開始されます。</li>
          <li>Excel登録フォーム内の表示が<b>登録完了</b>となれば、完了です。</li>
        </ol>
      </div>
    </div>
    <div id="filepond-tools-area">
      <p id="filepond-title">
        Excel登録フォーム
      </p>
    </div>
    <file-pond
      ref="pond"
      class="filepond-area"
      name="sutudents-uploader"
      @addfile="filepondAddFilesEvent"
      @removefile="filepondRemoveFilesEvent"
      @processfile="filepondProcessFinishedEvent"
      @error="filepondProcessErrorEvent"
    />
    <div class="btn-group">
      <v-row justify="end">
        <v-btn
          id="filepond-process-btn"
          color="primary"
          :disabled="!activateProcessBtn"
          class="btn btn-wd btn-fill btn-info mx-1 my-3 font-weight-bold"
          @click="filepondProcessFiles"
        >
          登録開始
        </v-btn>

        
        <v-btn
          id="filepond-process-btn"
          color="error"
          :disabled="!activateRemoveBtn"
          class="btn btn-wd btn-fill btn-danger my-3 mx-1 font-weight-bold"
          @click="filepondRemoveFiles"
        >
          取り消し
        </v-btn>

        <GeneratePdfDetail
          class="mx-1 my-3"
          :is-able="!isCompleteValidate"
        />

        <GeneratePdfOverview
          class="mx-1 my-3"
          :is-able="!isCompleteValidate"
        />

        <v-btn
          class="my-3 mx-1 font-weight-bold"
          color="primary"
          :disabled="!isCompleteValidate"
          @click="createZengin()"
        >
          請求データ
        </v-btn>
      </v-row>
    </div>
    <div v-if="show_result">
      <ResultTable
        :aggregated_value="this.aggregated_value"
        :total_num="this.total_num"
        :total_money_amount="this.total_money_amount"
      />
    </div>
  </div>
</template>
<script>
import createZenginF from '@/utils/create_zenginformat'
import { mdiDownload } from '@mdi/js'
import axios from 'axios'
import swal from 'sweetalert2'
import vueFilePond, { setOptions } from 'vue-filepond'
import 'filepond/dist/filepond.min.css'

// Import image preview plugin styles
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ResultTable from '@/components/ResultTable.vue'
import GeneratePdfDetail from '@/components/GeneratePdfDetail.vue'
import GeneratePdfOverview from '@/components/GeneratePdfOverview.vue'
import initOptions from '@/assets/import-excel/uploaderOptions'

// import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,

  // FilePondPluginImagePreview
)

// const apiDomain = 'https://api.console-gakkopay.com'

export default {
  components: {
    FilePond,
    ResultTable,
    GeneratePdfDetail,
    GeneratePdfOverview,
  },
  data() {
    return {
      aggregated_value: [],
      show_result: false,
      header_record: [],
      data_record: [],
      total_num: 0,
      total_money_amount: 0,
      // is_set_furikae_date: false,
      // is_set_sousin_date: false,
      icons: {
        mdiDownload,
      },
      isCompleteValidate: false,
      isProcessBtn: false,
      isRemoveBtn: false,
      header: {},
      data: [],
    }
  },
  computed: {
    activateProcessBtn() {
      return this.isProcessBtn
    },
    activateRemoveBtn() {
      return this.isRemoveBtn
    },
    studentRegister() {
      return this.$store.getters.studentRegisterData
    },
  },
  created() {
    setOptions(initOptions(this.$route.query.grade))
  },
  methods: {
    createZengin() {
      createZenginF(this.$store.state.zengin_data.data_from_excel)
    },

    clickDownload() {
      const label = '全銀パイプライン_顧客請求情報_テンプレート.xlsx'
      const filename = '20240328_zengin-pipeline_excel-template.xlsx'
      const url = `https://zenginpipeline-public.s3-ap-northeast-1.amazonaws.com/${filename}`
      axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },

    // filepondに関わるボタンの制御用
    filepondAddFilesEvent(erorr, file) {
      if (erorr) {
        swal
          .fire({
            title: 'ファイルの形式が間違っています',
            text: 'Excelファイルを選択してください',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
          .then(result => {
            if (result.value) {
              this.$refs.pond.removeFile(file)
            }
          })
      } else if (this.$refs.pond.getFiles().length > 0) {
        this.isProcessBtn = true
        this.isRemoveBtn = true
        this.isCompleteValidate = false
      }
    },

    // filepondに関わるボタンの制御用
    filepondRemoveFilesEvent() {
      if (this.$refs.pond.getFiles().length > 0) this.isProcessBtn = true
      else {
        this.isProcessBtn = false
        this.isRemoveBtn = false
        this.isCompleteValidate = false
      }
    },
    set_aggregated_value() {
      // 取得した配列の長さ分だけ以下を実行
      if (this.$store.state.zengin_data.data_from_excel != {}) {
        for (let row = 0; row < this.$store.state.zengin_data.data_from_excel['furikae_data'].length; row++) {
          let tmp = {
            consignor_code: this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['header_record'][0],
            bank_name_ruby: this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['header_record'][2],
            bank_code: this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['header_record'][3],
            total_num: this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['total_num'],
            total_money_amount:
              this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['total_money_amount'].toLocaleString(),
          }
          this.total_num += this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['total_num']
          this.total_money_amount +=
            this.$store.state.zengin_data.data_from_excel['furikae_data'][row]['total_money_amount']
          this.aggregated_value.push(tmp)
        }
        this.show_result = true
      }
    },
    filepondProcessFiles() {
      this.$refs.pond.processFiles().then(() => {
        this.set_aggregated_value()
        this.show_result = true
        this.isProcessBtn = false
        this.isRemoveBtn = true
        this.isCompleteValidate = true
      })
    },

    filepondRemoveFiles() {
      this.$refs.pond.removeFiles().then(() => {
        this.$emit('isnext', false)
        this.isProcessBtn = false
        this.isRemoveBtn = false
        this.isCompleteValidate = false
        this.show_result = false
        this.aggregated_value = []
        this.total_num = 0
        this.total_money_amount = 0
      })
    },

    // filepondのProcessが完了したときに実行
    filepondProcessFinishedEvent() {
      this.$emit('isnext', true)
      const container = document.getElementById('filepond-tools-area')
      container.scrollIntoView({ behavior: 'smooth' })
    },

    // filepondのProcess中にエラーが発生したときに実行
    filepondProcessErrorEvent(error, file) {
      console.error('body: ', error.body)
      let error_message = '記入に不備がある可能性があります。'
      if (error.body.includes('invalid bank_code=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の「契約/解約」情報に誤りがある可能性があります。`
      } else if (error.body.includes('invalid_is_acc=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の請求の「有/無」に誤りがある可能性があります。`
      } else if (error.body.includes('invalid_is_claim=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の支店名もしくは支店コードに誤りがある可能性があります。`
      } else if (error.body.includes('invalid branch_code=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の支店名もしくは支店コードに誤りがある可能性があります。`
      } else if (error.body.includes('invalid_bank_name=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の銀行名に誤りがあります。`
      } else if (error.body.includes('invalid_branch_name=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の支店名に誤りがあります。`
      } else if (error.body.includes('invalid_acc_holder=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の預金者名に誤りがあります。`
      } else if (error.body.includes('invalid_bank_code=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の銀行コードに誤りがあります。`
      } else if (error.body.includes('invalid_branch_code=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の支店コードに誤りがあります。`
      } else if (error.body.includes('invalid_acc_type=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の預金科目に誤りがあります。`
      } else if (error.body.includes('invalid_acc_num=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の口座番号に誤りがあります。`
      } else if (error.body.includes('invalid_customer_code=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の顧客番号に誤りがあります。`
      } else if (error.body.includes('invalid_money_amount=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目の引落請求金額に誤りがあります。`
      } else if (error.body == 'no data') {
        error_message = '振替を行うデータが見つかりません。'
      } else if (error.body.includes('invalid confignor or bank_code=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目に銀行設定にない金融機関コードが含まれています。`
      } else if (error.body.includes('invalid confignor or bank_name=')) {
        const error_row_num = error.body.split('=')[1]
        error_message = `${error_row_num}行目に銀行設定にない金融機関名が含まれています。`
      }
      swal
        .fire({
          title: '登録に失敗しました',
          text: `${error_message}`,
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: 'やり直す',
        })
        .then(result => {
          if (result.value) {
            this.$refs.pond.removeFile(file)
          }
        })
    },
  },
}
</script>
<style>
div.step-body {
  margin: 0 auto;
  width: 80%;
}
.filepond--root {
  font-family: Noto Sans JP;
  font-size: 20px;
}
.filepond--panel-root {
  background-color: transparent;
  border: 2px solid #2c3340;
}
[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: #3cb371;
}

.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}
.filepond--file-info-main,
.filepond--file-status-main {
  line-height: initial;
  font-size: 0.8em !important;
}
.filepond--progress-indicator .filepond--process-indicator {
  font-size: 20px;
}
.step-title-area {
  text-align: center;
  margin: 0 20% 30px 20%;
}
.step-title {
  font-size: 30px;
  color: #1d6fda;
  font-weight: bold;
}
.step-title-hr {
  border-top: 2px solid #1d6fda;
}
.step-detail-area {
  padding-bottom: 20px;
}
.step-detail-alert-area {
  margin: 0 1% 0 1%;
  padding: 1%;
}
.step-detail-alert-area > ol {
  background: #dadada3b; /*灰色に*/
  border-radius: 8px;
  box-shadow: 0px 0px 5px silver;
  padding: 0.5em 0.5em 0.5em 2em;
}
.step-detail-alert-area > ol > li {
  padding: 5px 0;
}
.step-detail-alert-area > ol > li > b {
  color: #e74d3ce0;
}
#filepond-tools-area {
  overflow: hidden; /*回り込み解除*/
}
#filepond-title {
  float: left;
  font-size: 22px;
  font-weight: bold;
}
#filepond-tools-area .btn-group {
  float: right;
}

/* ファイル選択済みなのに，filepondの上端をクリックしてファイル選択出来てしまうバグ用 */
.filepond--browser {
  pointer-events: none;
}
.download-btn > i {
  padding-right: 5px;
}

@media screen and (max-width: 1680px) {
  .step-title {
    font-size: calc(2.5rem + ((1vw - 7.68px) * 0.5482));
  }
  .step-title-area {
    margin: 0 10% 30px 10%;
  }
}
@media screen and (max-width: 1024px) {
  div.step-body {
    margin: 0 auto;
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .step-title {
    font-size: calc(1.6rem + ((1vw - 3.2px) * 0.8949));
  }
  .step-title-area {
    margin: 0 0 30px 0;
  }
  div.step-body {
    width: 100%;
  }
  #filepond-title {
    font-size: calc(1.8rem + ((1vw - 3.6px) * 0.9828));
  }
  .filepond-area > div > div > label {
    font-size: calc(1.5rem + ((1vw - 3.6px) * 1.2285));
  }
}
</style>
