<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          銀行情報が登録されていません
        </v-card-title>

        <v-card-text>
          初めに、銀行設定を行ってください。
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="accept_go_setting_bank()"
          >
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <DataPickerFurikae />
    </v-row>
    <v-row justify="center" v-if="step1_finished">
      <DataPickerSousin />
    </v-row>
    <div v-if="step2_finished">
      <ImportExcel />
    </div>
  </div>
</template>
<script>
import ImportExcel from '@/components/ImportExcel.vue'
import DataPickerFurikae from '@/components/DataPickerFurikae.vue'
import DataPickerSousin from '@/components/DataPickerSousin.vue'
import router from '@/router'

export default {
  components: {
    ImportExcel,
    DataPickerFurikae,
    DataPickerSousin,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    step1_finished() {
      return this.$store.state.zengin_data.furikae_date != ''
    },
    step2_finished() {
      return this.$store.state.zengin_data.sousin_date != ''
    },
  },
  async mounted() {
    this.$store.state.zengin_data.furikae_date = ''
    this.$store.state.zengin_data.sousin_date = ''

    if (this.$store.getters['setting_bank/getSettingBank'].length == 0) {
      await this.$store.dispatch('setting_bank/readSettingBank')
    }
    if (this.$store.getters['setting_bank/getSettingBank'].length == 0) {
      this.dialog = true
    }
  },
  methods: {
    accept_go_setting_bank() {
      this.dialog = false
      router.push('bank-setting')
    },
  },
}
</script>
