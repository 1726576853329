<template>
  <div class="mb-10">
    <div class="step-body">
      <div class="step-title-area">
        <div id="step1-title" class="step-title">Step.1 振替日の選択</div>
        <hr class="step-title-hr" />
      </div>
      <div class="step-detail-area">
        <!-- <p>振替を行う日付を選択して設定します。</p> -->
        <div class="step-detail-alert-area">
          <ol>
            <li>下のカレンダーで振替日に設定する<b>日付をクリックして選択</b>してください。</li>
            <li><b>「日付を確定」ボタンをクリック</b>して、振替日が正しく選択されているかを確認してください。内容が正しければ、<b>「確定」ボタンをクリック</b>してください。</li>
            <li><b>日付が設定されました</b>と表示されたら、<b>「確認」ボタンをクリック</b>して、完了です。</li>
          </ol>
        </div>
      </div>
    
    <v-row justify="center">
      <v-date-picker
        v-model="picker"
        color="primary"
        locale="jp-ja"
        :day-format="date => new Date(date).getDate()"
      ></v-date-picker>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="end" class="mr-16">
          <v-btn color="primary" class="font-weight-bold" v-bind="attrs" v-on="on" :disabled="isAbleDate">日付を確定</v-btn>
        </v-row>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">日付を確定しますか？</v-card-title>

        <v-card-text>
          口座振替日を<div class="text-h5 font-weight-bold primary--text">{{this.picked_date}}</div>に設定しますか？
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">キャンセル</v-btn>
          <v-btn color="primary" @click="setFurikaeDate()" class="font-weight-bold">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="date_final_dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">日付を確定しました</v-card-title>

        <v-card-text>
          振替日を<div class="text-h5 font-weight-bold primary--text">{{this.picked_date}}</div>に設定しました
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="finish_furikae_date_setting()" class="font-weight-bold">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const picker = new Date().toISOString().substr(0, 10)

    return {
      picker,
      isAbleDate: false,
      dialog: false,
      date_final_dialog: false,
    }
  },
  computed: {
    picked_date() {
      const picked_date = new Date(this.picker)
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      // // 過去の日付を許可するか
      // if (picked_date.getTime() > today.getTime()) {
      //   this.isAbleDate = false
      // } else {
      //   this.isAbleDate = true
      // }

      const picked_year = picked_date.getFullYear()
      let picked_month = picked_date.getMonth()
      picked_month += 1 // getMonth()は1月~12月を0~11で返すので+1する
      const picked_day = picked_date.getDate()
      return picked_year + '年' + picked_month + '月' + picked_day + '日'
    },
  },
  methods: {
    setFurikaeDate() {
      this.dialog = false
      this.$store.commit('zengin_data/setFurikaeDate', this.picker)
      this.date_final_dialog = true
    },
    finish_furikae_date_setting() {
      this.date_final_dialog = false
      const container = document.getElementById('step2-title')
      container.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>
