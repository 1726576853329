<template>
  <v-btn @click="previewPdf" :disabled="isAble" color="#1E88E5" class="white--text font-weight-bold">PDFデータ確認</v-btn>
</template>

<script>
import pdfMake from 'pdfmake'
// 以下の.jsファイルは必須（importから排除しないように注意）
import pdfFonts from '../../public/pdfmake/vfs_fonts'

export default {
  props: ['isAble'],
  data() {
    return {}
  },
  methods: {
    previewPdf() {
      let content = []
      // ここでフォントを指定
      pdfMake.fonts = {
        GenShin: {
          normal: 'GenShinGothic-Normal-Sub.ttf',
          bold: 'GenShinGothic-Normal-Sub.ttf',
          italics: 'GenShinGothic-Normal-Sub.ttf',
          bolditalics: 'GenShinGothic-Normal-Sub.ttf',
        },
      }

      console.log('furikae_data', this.$store.state.zengin_data.data_from_excel['furikae_data'])

      // 銀行毎に表を作成する
      for (let i = 0; i < this.$store.state.zengin_data.data_from_excel['furikae_data'].length; i++) {
        // ヘッダーレコードの情報を表示
        const header_record = this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['header_record']
        const data_record = this.$store.getters['zengin_data/get_data_from_excel']['furikae_data'][i]['data_record']
        // const data_record = this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['data_record']
        const total_num = this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['total_num']
        const total_money_amount =
          this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['total_money_amount']
        content.push({
          text: `委託者コード: ${header_record[0]}\n金融機関名(ｶﾅ): ${header_record[2]}\n金融機関コード: ${
            header_record[3]
          }\n合計件数: ${total_num}件\n合計金額: ¥${total_money_amount.toLocaleString()}`,
        })
        // 空の表を用意
        var table_content = {
          table: {
            widths: 'auto',
            body: [[]],
          },
        }
        // 表の見出しを作成
        var table_head = [
          '銀行名(ｶﾅ)',
          '金融機関コード',
          '支店名(ｶﾅ)',
          '支店コード',
          '種別',
          '口座番号',
          '預金者名(ｶﾅ)',
          '引落金額',
          '顧客コード',
        ]
        var head_tr = []
        for (let v of table_head) {
          head_tr.push({
            text: v,
            fillColor: '#dddddd',
            style: ['tableHeader', 'tableCell'],
          })
        }
        table_content['table']['body'][0] = head_tr
        for (let row = 0; row < data_record.length; row++) {
          // .concat()をつけることで，値渡しを可能にする
          table_content['table']['body'].push(data_record[row].concat())
        }
        content.push(table_content)
        content.push({ text: '\n\n' })
      }
      content.push({ text: '以上' })

      pdfMake
        .createPdf({
          content: content,
          defaultStyle: {
            font: 'GenShin', //ここでデフォルトのスタイル名を指定しています。
            fontSize: 10,
          },
        })
        .open()
      // .download('ファイル名.pdf')
    },
  },
}
</script>