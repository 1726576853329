require('date-utils')

function strPad(STR, DIR, LEN, PAD_STR) {
  if (DIR === 'left') {
    return (STR + Array(LEN + 1).join(PAD_STR)).slice(0, LEN)
  }

  return (Array(LEN + 1).join(PAD_STR) + STR).slice(-LEN)
}

function strTrans(STR) {
  // const trans_pattern = ['ｧｨｩｪｫｯｬｭｮ', 'ｱｲｳｴｵﾂﾔﾕﾖ']
  const trans_pattern = [
    ['ｧ', 'ｱ'],
    ['ｨ', 'ｲ'],
    ['ｩ', 'ｳ'],
    ['ｪ', 'ｴ'],
    ['ｫ', 'ｵ'],
    ['ｯ', 'ﾂ'],
    ['ｬ', 'ﾔ'],
    ['ｭ', 'ﾕ'],
    ['ｮ', 'ﾖ'],
  ]
  let result_str = STR

  for (const tar_char of trans_pattern) {
    result_str = result_str.replace(new RegExp(tar_char[0], 'g'), tar_char[1])
  }

  return result_str
}

function accTypeName2Code(STR) {
  let acc_type_code = ''
  if (String(STR) === String('普通')) acc_type_code = '1'
  else if (String(STR) === String('当座')) acc_type_code = '2'
  else throw new Error('error_accType')

  return acc_type_code
}

function valid120Byte(STR_ROW) {
  let bytes = 0
  for (let i = 0; i < STR_ROW.length; i += 1) {
    const c = STR_ROW.charCodeAt(i)
    if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
      bytes += 1
    } else {
      bytes += 2
    }
  }

  if (bytes !== 120) {
    throw new Error(`error_120Byte ->${bytes}`)
  }
}

export default function createZenginF(data) {
  const ary_zengin_fmt = []

  const { sousin_date, furikae_date, furikae_data } = data

  // console.log(furikae_date, furikae_data)

  const ary_furikae_data = String(furikae_date).split('-')
  const ary_sousin_data = String(sousin_date).split('-')

  // 振替日を整形
  const furikae_month = ary_furikae_data[1].padStart(2, '0')
  const furikae_day = ary_furikae_data[2].padStart(2, '0')

  // データ送信日を整形
  const sousin_month = ary_sousin_data[1].padStart(2, '0')
  const sousin_day = ary_sousin_data[2].padStart(2, '0')

  for (const furikae_data_item of furikae_data) {
    const { data_record, header_record } = furikae_data_item

    // 口座種別を整形
    // console.log('head: ', String(header_record[6]))
    // const hd_acc_type_code = accTypeName2Code(String(header_record[6]))
    const hd_acc_type_code = strPad(String('0'), 'right', 1, '0')

    // ヘッダレコードを作成
    const hd_type_code = '91'
    const hd_str_encode = '0'
    const hd_consignor_code = strPad(String(header_record[0]), 'right', 10, '0')
    const hd_consignor_name = strTrans(strPad(String(header_record[1]), 'left', 40, ' '))

    const hd_bank_code = strPad(String(header_record[3]), 'right', 4, '0')
    const hd_bank_name = strTrans(strPad(String(header_record[2]), 'left', 15, ' '))
    const hd_branch_code = strPad(String('000'), 'right', 3, '0')
    const hd_branch_name = strTrans(strPad(String(' '), 'left', 15, ' '))
    const hd_acc_num = strPad(String('0000000'), 'right', 7, '0')
    const hd_service_type = strPad(String('50'), 'right', 2, '0')

    const hd_parent_bank_code = strPad(String('8740'), 'right', 4, '0')
    const hd_div_code = strPad(String('01'), 'right', 2, '0')
    const hd_gen_cycle = strPad(String('00'), 'right', 2, '0')
    const hd_div_send_receive = strPad(String('2'), 'right', 1, '0')
    const hd_relay_center_code = strPad(String('T'), 'right', 1, '0')
    const hd_data_confirm_code = strPad(String('1'), 'right', 1, '0')

    const str_header_record = `1${hd_type_code}${hd_str_encode}${hd_consignor_code}${hd_consignor_name}${furikae_month}${furikae_day}${hd_bank_code}${hd_bank_name}${hd_branch_code}${hd_branch_name}${hd_acc_type_code}${hd_acc_num}${hd_service_type}${sousin_month}${sousin_day}${hd_parent_bank_code}${hd_div_code}${hd_gen_cycle}${hd_div_send_receive}${hd_relay_center_code}${hd_data_confirm_code}`

    valid120Byte(str_header_record)
    ary_zengin_fmt.push(str_header_record)

    // console.log(str_header_record)

    // トレーラーレコード用の集計
    let tr_qty_data = 0
    let tr_sum_amount = 0

    // データレコードを作成
    for (const data_record_item of data_record) {
      // 口座種別を整形
      // console.log('data: ', JSON.stringify(data_record_item[4]))
      const dt_acc_type_code = accTypeName2Code(data_record_item[4])

      const dt_bank_code = strPad(String(data_record_item[1]), 'right', 4, '0')
      const dt_bank_name = strTrans(strPad(String(data_record_item[0]), 'left', 15, ' '))
      const dt_branch_code = strPad(String(data_record_item[3]), 'right', 3, '0')
      const dt_branch_name = strTrans(strPad(String(data_record_item[2]), 'left', 15, ' '))
      const dt_dummy1 = String('').padStart(4, ' ')
      const dt_acc_num = strPad(String(data_record_item[5]), 'right', 7, '0')
      const dt_acc_name = strPad(String(data_record_item[6]), 'left', 30, ' ')
      const dt_amount = strPad(String(data_record_item[7]), 'right', 10, '0')
      const dt_new_code = '0'
      const dt_customer_code = strPad(String(data_record_item[8]), 'right', 20, ' ')
      const dt_result_flag = '0'
      const dt_dummy2 = strPad(String('000'), 'right', 3, '0')
      const dt_dummy3 = String('').padStart(5, ' ')

      const str_data_record = `2${dt_bank_code}${dt_bank_name}${dt_branch_code}${dt_branch_name}${dt_dummy1}${dt_acc_type_code}${dt_acc_num}${dt_acc_name}${dt_amount}${dt_new_code}${dt_customer_code}${dt_result_flag}${dt_dummy2}${dt_dummy3}`

      valid120Byte(str_data_record)
      ary_zengin_fmt.push(str_data_record)

      tr_qty_data += 1
      tr_sum_amount += Number(dt_amount)

      // console.log(tr_qty_data, tr_sum_amount)
    }

    // トレーラーレコード作成
    const tr_all_qty_data = strPad(String(tr_qty_data), 'right', 6, '0')
    const tr_all_sum_amount = strPad(String(tr_sum_amount), 'right', 12, '0')
    const tr_ok_qty_data = String('').padStart(6, '0')
    const tr_ok_sum_amount = String('').padStart(12, '0')
    const tr_ng_qty_data = String('').padStart(6, '0')
    const tr_ng_sum_amount = String('').padStart(12, '0')
    const tr_dummy = String('').padStart(65, ' ')

    const str_trailer_record = `8${tr_all_qty_data}${tr_all_sum_amount}${tr_ok_qty_data}${tr_ok_sum_amount}${tr_ng_qty_data}${tr_ng_sum_amount}${tr_dummy}`
    valid120Byte(str_trailer_record)
    ary_zengin_fmt.push(str_trailer_record)
  }

  const ed_dummy = String('').padStart(119, ' ')
  const str_end_record = `9${ed_dummy}`

  valid120Byte(str_end_record)
  ary_zengin_fmt.push(str_end_record)

  // console.log(ary_zengin_fmt)

  export_zengin_fmt(ary_zengin_fmt)

  return ary_zengin_fmt
}

// 作成された全銀Fが格納してある配列を銀行コードで昇順に並べる
function sort_zengin_list(zengin_fmt_list) {
  const zengin_fmt_by_bank = {}
  const bank_code_list = []
  const sorted_zengin_fmt = []

  // 全銀Fの配列を網羅（エンドレコードが出てくるまで回す）
  let row = 0
  let bank_code = ''
  let zengin_row = ''
  zengin_row = zengin_fmt_list[row]
  while (zengin_row.charAt(0) != '9') {
    zengin_row = zengin_fmt_list[row]

    // 取得した行がヘッダーレコード(ヘッダーレコードが1から始まる)
    if (zengin_row.charAt(0) == '1') {
      bank_code = zengin_row.substr(58, 4)
      bank_code_list.push(bank_code)
      zengin_fmt_by_bank[bank_code] = []
    }

    // 取得した行がデータレコードもしくはトレーラーレコード
    else if (zengin_row.charAt(0) == '2' || zengin_row.charAt(0) == '8') {
      zengin_fmt_by_bank[bank_code].push(zengin_row)
    }
    row++
  }
  bank_code_list.sort()
  for (let i = 0; i < bank_code_list.length; i++) {
    bank_code = bank_code_list[i]
    for (let j = 0; j < zengin_fmt_by_bank[bank_code].length; j++) {
      sort_zengin_list.push(zengin_fmt_by_bank[bank_code][j])
    }
  }

  // エンドレコードを末尾に追加
  sorted_zengin_fmt.push(zengin_fmt_list[zengin_fmt_list.length - 1])

  return sorted_zengin_fmt
}

// 全銀Fをファイル出力
const Encoding = require('encoding-japanese')

function export_zengin_fmt(zengin_fmt_list) {
  let text_file_str = ''
  for (let i = 0; i < zengin_fmt_list.length; i++) {
    // エンドレコードの際は改行コードを入れない
    if (zengin_fmt_list[i].charAt(0) == '9') {
      text_file_str += zengin_fmt_list[i]
    } else {
      text_file_str += `${zengin_fmt_list[i]}\n`
    }
  }
  text_file_str = Encoding.stringToCode(text_file_str)

  const dt = new Date()
  let filename = dt.toFormat('YYYYMMDDHH24MISS')
  console.log(filename)

  const converted_text = new Uint8Array(Encoding.convert(text_file_str, 'sjis', 'unicode'))
  const blob = new Blob([converted_text], { type: 'text/plain' })
  const text_download_link = document.createElement('a')
  text_download_link.href = URL.createObjectURL(blob)
  text_download_link.download = filename + '.txt'
  text_download_link.click()
}
