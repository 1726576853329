import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" 銀行情報が登録されていません ")]),_c(VCardText,[_vm._v(" 初めに、銀行設定を行ってください。 ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.accept_go_setting_bank()}}},[_vm._v(" 確認 ")])],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c('DataPickerFurikae')],1),(_vm.step1_finished)?_c(VRow,{attrs:{"justify":"center"}},[_c('DataPickerSousin')],1):_vm._e(),(_vm.step2_finished)?_c('div',[_c('ImportExcel')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }