<template>
  <div class="mt-10">
    <v-row class="mb-n6">
      <v-col>
        <p class="text-h5 font-weight-bold">振替日{{ this.furikae_date }}</p>
      </v-col>
      <v-col align-self="end">
        <p class="text-left text-h6 font-weight-bold">合計{{ this.total_num }}件　合計¥{{ this.total_money_amount.toLocaleString() }}</p>
      </v-col>
    </v-row>

    <v-simple-table class="mt-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">委託者コード</th>
            <th class="text-center text-uppercase">金融機関(ｶﾅ)</th>
            <th class="text-center text-uppercase">金融機関コード</th>
            <th class="text-center text-uppercase">合計件数</th>
            <th class="text-center text-uppercase">合計金額</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in aggregated_value" :key="item.consignor_code">
            <td>{{ item.consignor_code }}</td>
            <td class="text-center">
              {{ item.bank_name_ruby }}
            </td>
            <td class="text-center">
              {{ item.bank_code }}
            </td>
            <td class="text-center">
              {{ item.total_num }}
            </td>
            <td class="text-center">
              {{ item.total_money_amount }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: ['aggregated_value', 'total_num', 'total_money_amount'],
  data() {
    return {}
  },
  computed: {
    furikae_date() {
      return this.$store.state.zengin_data.furikae_date
    },
  },
}
</script>
