<template>
  <v-btn @click="previewPdf" :disabled="isAble" color="#1E88E5" class="white--text font-weight-bold">PDF送付状</v-btn>
</template>

<script>
import pdfMake from 'pdfmake'
// 以下の.jsファイルは必須（importから排除しないように注意）
import pdfFonts from '../../public/pdfmake/vfs_fonts'

export default {
  props: ['isAble'],
  data() {
    return {}
  },
  methods: {
    previewPdf() {
      let content_array = []
      // ここでフォントを指定
      pdfMake.fonts = {
        GenShin: {
          normal: 'GenShinGothic-Normal-Sub.ttf',
          bold: 'GenShinGothic-Normal-Sub.ttf',
          italics: 'GenShinGothic-Normal-Sub.ttf',
          bolditalics: 'GenShinGothic-Normal-Sub.ttf',
        },
      }

      const pdf_style = {
        header: {
          fontSize: 22,
          bold: true,
        },
        basicTextStyle: {
          fontSize: 24,
          // italics: true,
          alignment: 'center',
          // decoration: 'underline',
        },
        leftTextStyle: {
          fontSize: 24,
          // italics: true,
          alignment: 'center',
          // decoration: 'underline',
        },
        basicNumberStyle: {
          fontSize: 24,
          // italics: true,
          alignment: 'center',
          // decoration: 'underline',
        },
      }

      console.log('furikae_data', this.$store.state.zengin_data.data_from_excel['furikae_data'])
      // 銀行毎に表を作成する
      for (let i = 0; i < this.$store.state.zengin_data.data_from_excel['furikae_data'].length; i++) {
        // ヘッダーレコードの情報を表示
        const header_record = this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['header_record']
        const data_record = this.$store.getters['zengin_data/get_data_from_excel']['furikae_data'][i]['data_record']
        // const data_record = this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['data_record']
        const total_num = this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['total_num']
        const total_money_amount =
          this.$store.state.zengin_data.data_from_excel['furikae_data'][i]['total_money_amount']

        const furikae_date = this.$store.state.zengin_data.data_from_excel['furikae_date']

        // let content = {
        //   text: `\委託者コード:  ${header_record[0]}\n\n\委託先名:  ${
        //     header_record[1]
        //   }\n\n\振替日:  ${furikae_date}\n\n\合計件数:  ${total_num}件\n\n\合計金額:  ¥${total_money_amount.toLocaleString()}`,
        //   style: 'basicStyle',
        // }

        const content = [
          [
            { text: '委託者コード:', style: 'leftTextStyle' },
            { text: header_record[0], style: 'basicTextStyle' },
          ],
          [
            { text: '委託先名:', style: 'leftTextStyle' },
            { text: header_record[1], style: 'basicTextStyle' },
          ],
          [
            { text: '振替日:', style: 'leftTextStyle' },
            { text: furikae_date, style: 'basicTextStyle' },
          ],
          [
            { text: '合計件数:', style: 'leftTextStyle' },
            { text: `${total_num}件`, style: 'basicNumberStyle' },
          ],
          [
            { text: '合計金額:', style: 'leftTextStyle' },
            { text: `${total_money_amount.toLocaleString()}円`, style: 'basicNumberStyle' },
          ],
        ]

        let page_template = {
          columns: [
            { width: '*', text: '' },
            {
              width: 'auto',
              layout: 'noBorders',
              table: {
                heights: 50,
                body: content,
                alignment: 'center',
              },
            },
            { width: '*', text: '' },
          ],
        }

        if (i > 0) {
          page_template['pageBreak'] = 'before'
        }

        content_array.push(page_template)
      }

      pdfMake
        .createPdf({
          styles: pdf_style,
          content: content_array,
          pageSize: 'A4',
          pageMargins: [0, 150, 0, 0],
          pageOrientation: 'landscape',
          defaultStyle: {
            font: 'GenShin', //ここでデフォルトのスタイル名を指定しています。
            fontSize: 10,
          },
        })
        .open()
      // .download('ファイル名.pdf')
    },
  },
}
</script>